.testCaseBoard {
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 32px;
  margin-top: 11px;
}

.boardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.header {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: left;
  margin: 5px auto 5px 0;
  width: 334px;
  padding-right: 244px;
}

.expectedResult {
  padding-right: 65px;
}

.importButtonContainer {
  margin-bottom: 15px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 8px 8px;
  gap: 15px;
}

.importButton {
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  background-color: #fff;
  border: solid 1.5px #b7b7b7;
  border-radius: 3px;
}

.runTestCase {
  width: 138px;
  height: 56px;
  background: linear-gradient(0deg, #484848, #484848), linear-gradient(0deg, #b7b7b7, #b7b7b7);
  border: 1.5px solid rgba(183, 183, 183, 1);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  color: #fff;
  border-radius: 3px;
}

.addTestCase {
  width: 138px;
  height: 56px;
  border: 1.5px solid rgba(183, 183, 183, 1);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  border-radius: 3px;
  background-color: #fff;
}
