.background {
  display: flex;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.secondBackground {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.testScenarioBackGround {
  padding: 10px 0;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.testScenario {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  box-sizing: border-box;
  text-align: center;
}

* {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.015em;
  text-align: left;
  margin: 10px auto;
}

h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: left;
  margin: 5px auto;
}

.userInputBoard {
  padding: 10px 32px;
  background-color: #ffffff;
  border-radius: 5px;
  margin: 5px 0;
  box-sizing: border-box;
}
