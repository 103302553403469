.indent {
  padding-left: 25px;
}

.testScenarioBackGround {
  padding: 10px 0;
}

.userInputBoard {
  margin-top: 11px;
  padding: 24px 30px 12px 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
}

.displayGroup {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.testButton {
  background-color: #ffffff;
  border: solid 2px #574ad1;
}
.textInputGroup {
  display: flex;
  align-items: flex-end;
}

.textDescription {
  line-height: 100%;
  margin-right: 20px;
}

.spanPaddingRight {
  padding-right: 80px;
  margin-right: 80px;
}

.descriptionBoard {
  padding: 10px;
  border: solid 1px #c9c9c9;
  box-sizing: border-box;
  flex-basis: 95%;
  display: flex;
  justify-content: space-between;
  height: 56px;
  text-overflow: ellipsis;
}

.description {
  font-size: 16px;
  padding-left: 10px;
  max-width: 850px;
  white-space: break-spaces;
}

.description p {
  margin: 0px;
  max-height: 34px;
  overflow: hidden;
  line-height: 17px;
  max-height: 34px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.select {
  width: 214px;
  padding-left: 12px;
}

.input {
  width: 214px;
  padding-left: 12px;
  box-sizing: border-box;
}
.datePicker {
  box-sizing: border-box;
  width: 216px;
}

.dateTimePicker {
  box-sizing: border-box;
}

.descriptionDateTime {
  font-size: 16px;
  padding-left: 10px;
  max-width: 850px;
  white-space: break-spaces;
}

.userInput_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 42px 15px 30px;
}

.header_left {
  display: flex;
  flex-basis: 50%;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
}

.header_right {
  display: flex;
  justify-content: flex-start;
  gap: 19px;
  align-items: center;
}
