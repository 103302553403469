.itemGroupDotted {
  padding: 12px 2px;
  /* margin-bottom: 15px; */
  /* border-bottom: dotted 1px gray; */
  border: solid 1px #dfdfdf;
}

.card {
  background-color: #ffffff;
  padding: 20px 32px;
  border-radius: 5px;
  flex-basis: 70%;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  margin-bottom: 5px;
}

.riskName {
  font-size: 18px;
}

.hr {
  height: 1px;
  border-width: 0;
  color: #bcbcbc;
  background-color: #bcbcbc;
}

.filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
}

.view {
  font-weight: bold;
  font-size: 14px;
}

.description {
  padding-left: 30px;
}

.description h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.015em;
  text-align: left;
  margin: 5px auto;
}

.snippet {
  font-size: 10px;
  margin: 0;
}

.warnIcon {
  position: relative;
  top: 8px;
}
