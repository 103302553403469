.impactLabelSpan {
  width: 170px;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  border: solid 1.5px rgba(255, 104, 104, 1);
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 30px;
  box-sizing: border-box;
  border-radius: 3px;
  flex-basis: 50%;
}

.warningItem {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.enterpriseImpactCardContainer {
  flex-basis: 30%;
  background: #ffffff;
  padding: 20px 32px;
  border-radius: 8px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
}

.description {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: left;
}

.warningGroup {
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid 1px rgba(188, 188, 188, 1);
}
