.itemGroupDotted {
  padding: 12px 2px;
  /* margin-bottom: 15px; */
  /* border-bottom: dotted 1px gray; */
  border: solid 1px #dfdfdf;
}

.card {
  box-shadow: 0px 3px 2px 0px rgb(0 0 0 / 25%);
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 32px;
  margin-top: 11px;
  margin-bottom: 11px;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  margin-bottom: 5px;
}

.riskName {
  font-size: 18px;
}

.hr {
  height: 1px;
  border-width: 0;
  color: #bcbcbc;
  background-color: #bcbcbc;
}

.filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
}

.view {
  font-weight: bold;
  font-size: 14px;
}

.description {
  padding-left: 30px;
}

.snippet {
  font-size: 10px;
  margin: 0px;
}

.warnIcon {
  position: relative;
  left: -25px;
  top: 8px;
}

.iframe {
  width: 100%;
  height: 80%;
  border-color: #bfbfbf;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
}

.select {
  width: 100%;
  height: 32px;
  padding-left: 12px;
}
