.scopeCardContainer {
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 28px 32px;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 16px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15);
}

.nameSnippet {
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: -0.015em;
  text-align: left;
  padding-bottom: 16px;
}

.scopeCard_leftContent {
  flex-basis: 50%;
  padding-right: 5px;
}

.scopeCard_rightContent {
  flex-basis: 50%;
  padding: 15px 0 15px 25px;
}

.descriptionParagraph {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: left;
}

.sourceDescription {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: left;
}

.otherSourceDescription {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.015em;
  text-align: left;
}

.buttonItem {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
}

.button {
  font-family: Montserrat, sans-serif;
  color: #000 !important;
  text-decoration: none !important;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.015em;
  text-align: center;
  padding: 7.5px 22.5px;
  border-radius: 3px;
  border: 1.5px solid rgba(183, 183, 183, 1);
  background-color: rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: 0.1s all;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.comeIntoEffectCard {
  background-color: rgba(255, 225, 225, 1);
  border-radius: 6px;

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: center;
  padding: 15px;
  margin-top: 15px;
}
