.permitted {
  border: solid 1.5px rgba(237, 177, 23, 1);
}

.forbidden {
  border: solid 1.5px rgba(255, 104, 104, 1);
}

.obligatory {
  border: solid 1.5px rgba(23, 197, 113, 1);
}

.basicStyle {
  text-align: center;
  border-radius: 3px;
  width: 136px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  padding: 5px 0px 5px 3px;
  box-sizing: border-box;
  background-color: #fff;
}

.icon {
  margin-left: 3px;
}

.word {
  font-weight: bold;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0em;
  flex-basis: 80%;
  text-align: center;
}

.noResult {
  background-color: rgba(176, 170, 170, 1);
  border: solid 1.5px rgba(176, 170, 170, 1);
  color: #fff;
}

.emptyResult {
  background-color: #fff;
  border: solid 1.5px rgba(176, 170, 170, 1);
  color: rgba(176, 170, 170, 1);
}