.cardBoard {
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 28px 32px;
  justify-content: space-between;
  gap: 15px;
}

.number {
  font-size: 50px;
}

.name {
  font-size: 20px;
}

.countCard {
  background-color: #f6f5f5;
  border-radius: 4px;
  padding: 16px;
  margin-left: 16px;
}

.countCardContainer {
  display: flex;
}

.organizationCardContainer {
  display: flex;
}

.organizationCard__header {
  padding: 0 15px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.organizationCard__content {
  padding: 0 15px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.015em;
  text-align: left;
}

.organizationCard__item {
  margin-bottom: 5px;
}
